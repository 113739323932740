import React from 'react';
import { MessageBanner } from './styled'
import { ICandidate } from '../types'

interface IMessageBoxProps {
    isWinner: boolean
    winner: null | string[]
    eliminated: Array<string>
    candidates: Array<ICandidate>
}

export function MessageBox({ isWinner, winner, eliminated, candidates }: IMessageBoxProps) {

    let Message = "";
    if (isWinner) {
        if (eliminated.length < candidates.length - 1) {
            Message = `${winner} wins by gaining majority vote!`
        }
        if (eliminated.length === candidates.length - 1) {
            Message = `${winner} wins by default!`
        }
        if (eliminated.length === candidates.length) {
            Message = "It's a tie! Press the button to revote"
        }
    }
    else Message = "Press button to simulate ranked choice voting c:"
    return (
        <MessageBanner>{Message}</MessageBanner>
    )
}
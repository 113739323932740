import React, {useState} from 'react'
import {
    Main, MainContainer, HeaderText,
    ResultsButton, TextWrap,
    CandidateHeader, BarChartContainerSvg,
    Tabs, Tab, TabText, MOBILEBREAKPOINT,
    VotersHeader, VotersContainer, DisplayRankedVote
} from '../components/styled'
import {MultiBarChart} from '../components/MultiBarChart'
import {MessageBox} from '../components/MessageBox'
import { ICandidate, IVoter, IRankedVote, IRound } from '../types'
import range from 'lodash.range'

import { CandidateList } from '../components/CandidateList'
import { useElementSize } from '../hooks/useElementSize'
import { CandidatesLegend } from '../components/CandidatesLegend'
import { Voter } from '../components/Voter'
import { SingleBarChart } from '../components/SingleBarChart'

function initializeCandidates(): Array<ICandidate> {
    return [
        {name: 'Jeffrey Peoples', voteCount: [], color: '#e4e117'},
        {name: 'Casper', voteCount: [], color: '#83d8a5'},
        {name: 'Shadow', voteCount: [], color: '#6df0f0'},
        {name: 'Katya Kozorezov', voteCount: [], color: '#d993ea'},
        {name: 'Mark Twain', voteCount: [], color: '#ff8282'}
    ]
}

function getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max))
}

function initializeVoters() {
    const initialVotersAcc: Array<IVoter> = []
    return range(0, 21).reduce((voters, id) => {
        const memoCandidates: Array<number> = []
        const getRandomCandidate = (num: number): ICandidate => {
            if (memoCandidates.includes(num)) {
                return getRandomCandidate(getRandomInt(5))
            }
            memoCandidates.push(num)
            return initializeCandidates()[num]
        }

        const votes: Array<string> = []
        const vote = range(1, 6).reduce((acc) => {
            acc.push(getRandomCandidate(getRandomInt(5)).name)
            return acc
        }, votes)
        const voter: IVoter = {id, vote}
        voters.push(voter)
        return voters
    }, initialVotersAcc)
}

export function Simulation() {

    let winnerName: null | string[] = null

    const [voters, setVoters] = useState<Array<IVoter>>(initializeVoters())
    console.log('Initial Voters', voters)
    const [candidates, setCandidates] = useState<Array<ICandidate>>(initializeCandidates())
    const [round, setRound] = useState<IRound>('Pre')
    const [eliminatedCandidates, setEliminatedCandidates] = useState<Array<string>>([])
    const [showFirstButton, setShowFirstButton] = useState<boolean>(true)
    const [selectedTab, setSelectedTab] = useState(1)
    const [rankedVote, setRankedVote] = useState<IRankedVote | null>(null)
    const { width } = useElementSize('root')
    const isMobile = typeof width === 'number' && width < MOBILEBREAKPOINT

    const refreshButton = () => {
        isWinner = false
        winnerName = null
        setRound('Pre')
        setShowFirstButton(true)
        setVoters(initializeVoters())
        setCandidates(initializeCandidates())
        setEliminatedCandidates([])

    }

    const resultsButton = () => {

        const resultsButton = document.getElementById('resultsButton')
        resultsButton!.style.backgroundColor = 'wheat'
        resultsButton!.style.boxShadow = '0px 0px 0px 0px'

        setTimeout(() => {
            resultsButton!.style.backgroundColor = 'cornsilk'
            resultsButton!.style.boxShadow = '0px 1px 4px 0px'

        }, 110)

        if (round === 'Pre') {
            console.log('Initial Voters', voters)
            setShowFirstButton(false)
            const candidatesClone = [...candidates]
            voters.forEach((voter: IVoter) => {
                const votesArray = voter.vote
                const firstRankCandidate = votesArray[0]
                const candidate = candidatesClone.find(obj => obj.name === firstRankCandidate)!
                const voteCount = candidate.voteCount[0]
                if (voteCount) {
                    voteCount.count += 1
                } else {
                    const newCandidateCount = {
                        count: 1,
                        color: candidate.color,
                        voterTopChoice: candidate.name,
                        rank: 1
                    }
                    candidate.voteCount.push(newCandidateCount)
                }
            })

            setRound(1)
            candidatesClone.sort(function (a, b) {
                const candidateACount = getTotalCandidateVotes(a)
                const candidateBCount = getTotalCandidateVotes(b)
                return candidateBCount - candidateACount
            })
            setCandidates(candidatesClone)
            console.log('Elimination Round', round)

        } else if (typeof round === 'number') {
            if (winnerName !== null || eliminatedCandidates.length === candidates.length) {
                console.log('REFRESHING')
                refreshButton()
            } else {
                let leastVotes: number = candidates.reduce((min, candidate) => {
                    const total = getTotalCandidateVotes(candidate)
                    if (!eliminatedCandidates.includes(candidate.name) && total < min) {
                        return total
                    }
                    return min
                }, voters.length)
                console.log('leastVotes', leastVotes)
                let losers = candidates.filter(function (candidate) {
                    const total = getTotalCandidateVotes(candidate)
                    return total === leastVotes
                }).map((candidate) => candidate.name)
                losers = [...eliminatedCandidates, ...losers]
                console.log('losers', losers)
                const updatedCandidates = initializeCandidates()
                const nextRound = round + 1
                voters.forEach((voter) => {
                    const votesArray = voter.vote
                    let vote: string | null = null
                    for (let i = 0; i < votesArray.length; i++) {
                        const possibleVote = votesArray[i]
                        if (!losers.includes(possibleVote)) {
                            vote = possibleVote
                            break
                        }
                    }

                    if (vote) {
                        const candidateIndex = updatedCandidates.findIndex(obj => obj.name === vote)
                        const candidate = updatedCandidates[candidateIndex]
                        const candidateVote = candidate.voteCount.find(v => v.voterTopChoice === voter.vote[0])
                        if (candidateVote) {
                            candidateVote.count += 1
                        } else {
                            const voterTopChoice = candidates.find(c => c.name === voter.vote[0])!
                            const rank = losers.findIndex((loser) => loser === voterTopChoice.name) + 1
                            candidate.voteCount.push({
                                count: 1,
                                color: voterTopChoice.color,
                                voterTopChoice: voterTopChoice.name,
                                rank
                            })
                        }
                    }
                })
                console.log('Updated Candidates', updatedCandidates)

                setRound(nextRound)
                setEliminatedCandidates(losers)
                updatedCandidates.sort(function (a, b) {
                    const candidateACount = getTotalCandidateVotes(a)
                    const candidateBCount = getTotalCandidateVotes(b)
                    return candidateBCount - candidateACount
                })
                setCandidates(updatedCandidates)
            }
        }
    }

    const tabClicked = (num: number) => {
        setSelectedTab(num)
    }
    function switchGraphContent() {
        let content = null
        switch (selectedTab) {
            case 1:
                content = <SingleBarChart voters={voters} candidates={candidates} />
                break;
            case 2:
                content = <MultiBarChart voters={voters} candidates={candidates} round = {round}/>
                break;

            case 3:
                content = <div>COMING SOON</div>
                break;
            default:
                content = <BarChartContainerSvg />
                break;
        }
        return content;
    }

    const mostVotes = candidates.reduce((max, candidate) =>
        getTotalCandidateVotes(candidate) > max
            ? getTotalCandidateVotes(candidate)
            : max
        , getTotalCandidateVotes(candidates[0]))
    const majorityVote = voters.length / 2
    let isWinner = mostVotes > majorityVote || eliminatedCandidates.length === candidates.length - 1 || eliminatedCandidates.length === candidates.length
    if (isWinner) {
        winnerName = candidates.filter(function (item) {
            return getTotalCandidateVotes(item) === mostVotes
        }).map((candidate) => candidate.name)
        console.log('WINNER', winnerName)
    }


    return (
        <Main>
            <HeaderText>
                Ranked Choice Voting Simulator
            </HeaderText>
            {isMobile && <CandidatesLegend candidates={candidates} />}
            <ResultsButton id="resultsButton" onClick={(resultsButton)}>
                <TextWrap id="resultsText"> {showFirstButton ? 'First Choice' :
                    isWinner ? 'Refresh' : 'Next Choice'}

                </TextWrap>

            </ResultsButton>
            <MainContainer>
                {!isMobile && <Tabs>
                    <Tab isSelected={selectedTab === 1} onClick={() => { tabClicked(1) }}><TabText>Single-Colored</TabText></Tab>
                    <Tab isSelected={selectedTab === 2} onClick={() => { tabClicked(2) }}><TabText>Multi-Colored</TabText></Tab>
                    <Tab isSelected={selectedTab === 3} onClick={() => { tabClicked(3) }}><TabText>Tab Three</TabText></Tab>
                </Tabs>}

                {!isMobile && (
                    <>
                        <CandidateHeader>Candidates</CandidateHeader>
                        <CandidateList candidates={candidates} eliminatedCandidates={eliminatedCandidates} />
                    </>
                )}

                {switchGraphContent()}

            </MainContainer>
            {/*<VotingActions round = {round} />*/}
            <MessageBox isWinner={isWinner} winner={winnerName} eliminated={eliminatedCandidates}
                candidates={candidates} />
            <VotersHeader>Voters</VotersHeader>
            <VotersContainer>
                {
                    voters.map((voter) => {
                        return <Voter
                            key={voter.id}
                            voter={voter}
                            candidates={candidates}
                            eliminatedCandidates={eliminatedCandidates}
                            round={round}
                            rankedVote={rankedVote}
                            setRankedVote={setRankedVote}
                            isMobile={isMobile}
                        />
                    })
                }
            </VotersContainer>
            <DisplayRankedVote>
                {rankedVote === null ? "Click on a Voter to See their Ranked Choices" : "Click on a Different Voter to See their Choices"}
            </DisplayRankedVote>
        </Main>
    )
}

export function getTotalCandidateVotes(candidate: ICandidate): number {
    return candidate.voteCount.reduce((total, {count}) => {
        return total + count
    }, 0)
}

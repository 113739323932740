import React from "react";
import { IVoter, ICandidate, IRankedVote } from "../types";
import { Numb, ModalText, VoterModal, ModalHeader, NumberContainer, TextContainer } from "./styled";
import { PointerArrow } from "./PointerArrow";

interface IModalProps {
    voter: IVoter
    candidates: Array<ICandidate>
    rankedVote: IRankedVote | null
    iconColor: string | undefined
}


export function RankedChoicesModal({ voter, candidates, rankedVote, iconColor }: IModalProps) {
    if (!rankedVote) return null;
    const votes = rankedVote.votes
    const number: Array<JSX.Element> = [];
    const modalTexts = votes.map((name, index) => {
        const numb = <Numb key = {`modalNumber${index}`}>{index + 1}<br /></Numb>
        number.push(numb);
        for (let i = 0; i < candidates.length; i++) {
            const candidate = candidates[i]
            if (name === candidate.name) {
                const element = <ModalText key={`modal${candidate.name}`} candidateColor={candidate.color}>{name}</ModalText>
                return element;
            }
        }

    })

    return rankedVote.id === voter.id ?
        <VoterModal id="Modal">

            <ModalHeader>Ranked Choices</ModalHeader>
            <NumberContainer>{number}</NumberContainer><TextContainer>{modalTexts}</TextContainer>
            <PointerArrow rankedVote={rankedVote} candidates={candidates} iconColor={iconColor} />
        </VoterModal> : null
}
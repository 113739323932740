import React, {useState} from 'react'
import NodeGroup from 'react-move/NodeGroup'
import { ICandidate, IVoter } from '../types'
import { HashMap } from 'react-move'
import {easeCubicIn, easeCircleIn, easeExpIn, easeLinear} from 'd3-ease'
import { MOBILEBREAKPOINT } from './styled'

export interface IBarCandidate{
    x: number
    width: number
    color?: string
    voterTopChoice: string
    timeOffset?: number
    voteCount?: number
}
interface IBar{
    barCandidates: Array<IBarCandidate>
    voters: Array<IVoter>
    totalCandidateVotes: number
}
export function Bar({barCandidates, voters, totalCandidateVotes}: IBar){
    return (
        <NodeGroup
            data={barCandidates}
            keyAccessor={keyAccessor}
            start={startTransition}
            enter={enterTransition}
            update={updateTransition}
            leave={leaveTransition}
        >
            {transitionNodes(voters, totalCandidateVotes)}
        </NodeGroup>
    )
}

function transitionNodes(voters : Array<IVoter>, totalCandidateVotes: number) {
    const isMobile = window.innerWidth < MOBILEBREAKPOINT
    return (nodes: Array<any>): JSX.Element => {
        const elements = nodes.map(({key, data, state},index) => {
            
            const {width, x, opacity1, opacity2} = state as {width: number, x: number, opacity1: number, opacity2: number}
            // console.log('Opacity', opacity)
            
            if(index === nodes.length - 1){
                const dx = totalCandidateVotes > 9 ? "-25" : "-15"
                return (
                    <text
                        id = {key}
                        key = {key}
                        x={`${width}%`}
                        dx = {dx}
                        y={12} 
                        alignmentBaseline="central"
                        opacity = {opacity1 > 0 ? opacity1 : opacity2}
                        fontSize={isMobile ? "16px" : ".8vw"}
                    >
                        {totalCandidateVotes.toFixed(0)}
                    </text>
                )
            }
            const {color} = data as IBarCandidate
            const element = <rect id = {key} x = {`${x}%`} key = {key} width={`${width}%`} height="24px" fill={color}/>
            return element
        })
        
        return <>{elements}</>
    }
}


function keyAccessor(data: IBarCandidate, index: number) {
    return data.voterTopChoice
}

function startTransition(data: IBarCandidate, index: number): HashMap {
    return {
        width: 0,
        opacity1: 0,
        opacity2: 0,
        x: data.x
    }
}

function enterTransition(data: IBarCandidate, index: number): HashMap | Array<HashMap> {
    return [{
        width: [data.width],
        x: [data.x],
        opacity1: [1],
        opacity2: [0],
        timing: {
            duration: getDuration(data.voterTopChoice, data.voteCount, data.timeOffset), 
            delay:getDelay(index, data.voterTopChoice, data.timeOffset, true)
        }
    }]
}

function updateTransition(data: IBarCandidate, index: number): HashMap | Array<HashMap> {
    return [{
        width: [data.width],
        x:[data.x],
        timing: {
            duration: getDuration(data.voterTopChoice, data.voteCount, data.timeOffset), 
            delay:getDelay(index, data.voterTopChoice, data.timeOffset),
            ease: easeLinear
        }
    },
    {
        opacity1: [1, 0],
        timing: {
            duration: 250,
            delay: 200,
            ease: easeLinear
        }
    }
    ,{
        opacity2: [0,1],
        timing: {
            duration: getDuration(data.voterTopChoice, data.voteCount, data.timeOffset), 
            delay: 500,
            ease: easeExpIn
        }
    }
    ]
}

function leaveTransition(data: IBarCandidate, index: number): HashMap | Array<HashMap> {
    return [{
        opacity1: [0],
        opacity2: [0],
        timing: {duration: 500},
        width: [0],
        x: [0]
    }
    ]
}

function getDelay(index: number, voterTopChoice: string, timeOffset?: number, isStart?: boolean) : number { 
    if(index === 0 || voterTopChoice === "totalVotes"){
        if(isStart) return 0
        return 250
    }
    if(timeOffset === -1){
        return 500 * index
    }
    const offset = timeOffset || 0
    const baseDelay = index - offset
    return 500 * baseDelay
}

function getDuration(voterTopChoice: string, voteCount?: number, timeOffset?: number) : number {
    if(voterTopChoice === "totalVotes"){
        if(voteCount && timeOffset){
            return 500 * (voteCount - timeOffset)
        }
        return 500
    }
    return 500
}
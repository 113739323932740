import React from 'react';
import NodeGroup from 'react-move/NodeGroup';
import { HashMap } from 'react-move'
import { CandidatesSvg } from './styled';
import { ICandidate } from '../types';

interface IProps {
    candidates: Array<ICandidate>
    eliminatedCandidates: Array<string>

}

export function CandidateList({ candidates, eliminatedCandidates }: IProps) {
    return (
        <CandidatesSvg>
            <NodeGroup
                data={candidates}
                keyAccessor={keyAccessor}
                start={startTransition}
                enter={enterTransition}
                update={updateTransition}
                leave={leaveTransition}
            >
                {transitionNodes(eliminatedCandidates)}
            </NodeGroup>

        </CandidatesSvg>
    )
}

function transitionNodes(eliminatedCandidates: Array<string>) {
    return ((nodes: Array<any>): JSX.Element => {
        const elements = nodes.map(({ key, data, state }) => {
            const { name, color } = data as ICandidate
            const { y } = state as { y: number; }
            let showText = true;
            if (eliminatedCandidates.includes(name)) {
                showText = false;
            }
            return <g key={key} width="100%" transform={`translate(0, ${y})`} >
                <rect width="100%" height="24px" fill={color} />
                {showText && <text x="50%" y={12} textAnchor="middle" fontSize="1vw" alignmentBaseline="middle">{name}</text>}
            </g>


        })
        return <>{elements}</>
    })
}

function keyAccessor(data: ICandidate, index: number) {
    return data.name
}

function startTransition(data: ICandidate, index: number): HashMap {
    return {
        // y: index * (Math.round(window.innerHeight / 12.8))
        y: [(index + .2) * 63]
    }
}

function enterTransition(data: ICandidate, index: number): HashMap | Array<HashMap> {
    return [{
        timing: { duration: 500 },
        // y: [index * (Math.round(window.innerHeight / 12.8))]
        y: [(index + .2) * 63]
    }]
}

function updateTransition(data: ICandidate, index: number): HashMap | Array<HashMap> {
    return [{
        y: [(index + .2) * 63],
        timing: { duration: 500 }
    }]
}

function leaveTransition(data: ICandidate, index: number): HashMap | Array<HashMap> {
    return [{
    }
    ]
}

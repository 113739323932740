import React from 'react';
import Animate from 'react-move/Animate';
import { IVoter, ICandidate, IRankedVote } from "../types";
import { PointerArrowDiv } from './styled';

interface IPointerArrowProps {
    rankedVote: IRankedVote | null
    candidates: Array<ICandidate>
    iconColor: string | undefined

}

export function PointerArrow({ rankedVote, candidates, iconColor }: IPointerArrowProps) {

    let yValue;
    const matchingCandidate = candidates.filter(function (obj) { return obj.color === iconColor })

    if (iconColor === undefined) {
        yValue = 0
    } else {
        const chosenCandidateIndex = rankedVote?.votes.indexOf(matchingCandidate[0].name)
        yValue = `${chosenCandidateIndex && chosenCandidateIndex * 2}` + `${chosenCandidateIndex}`
        console.log(yValue)
        //y value of each numbered candidate
        //0:0 1:21 2:42 3:63 4:84
    }

    return (
        <PointerArrowDiv>
            <svg width="10px" height="143px" viewBox="0 11 10 143">
                <Animate
                    show={true}
                    start={{
                        translateY: yValue
                    }}
                    enter={{
                        translateY: [yValue],
                        timing: { duration: 300 }
                    }}
                    update={{
                        translateY: [yValue],
                        timing: { duration: 300 }
                    }}
                >
                    {({ translateY }) => {

                        let transformString = `translate(0, ${translateY})`;

                        return (
                            <polygon points="0 50, 10 43, 10 57" fill="black" transform={transformString} />
                        );
                    }}
                </Animate>
            </svg>
        </PointerArrowDiv>
    )
}
import { VoterImageContainer, VoterImage } from "./styled"
import React from "react"
import { IVoter, ICandidate, IRankedVote, IRound } from "../types"
import { RankedChoicesModal } from "./RankedChoicesModal"

interface IVoterProps {
    voter: IVoter
    candidates: Array<ICandidate>
    eliminatedCandidates: Array<string>
    round: IRound
    rankedVote: IRankedVote | null
    setRankedVote: React.Dispatch<React.SetStateAction<IRankedVote | null>>
    isMobile: boolean
}

export function Voter({ voter, candidates, eliminatedCandidates, round, rankedVote, setRankedVote, isMobile }: IVoterProps) {
    let voteAcc: string | null = null
    let foundCandidate = false
    // if list is long, or lots of voters, this could be significantly slower because I do not break early
    // for loop with break would be faster
    const vote = voter.vote.reduce((acc, name) => {
        if (foundCandidate || round === 'Pre') return acc
        if (eliminatedCandidates.includes(name)) {
            return acc
        }
        foundCandidate = true
        return name
    }, voteAcc)
    const color = candidates.find((candidate) => {
        return candidate.name === vote
    })?.color
    return (
        <>
            <VoterImageContainer>
                <RankedChoicesModal voter={voter} candidates={candidates} rankedVote={rankedVote} iconColor={color} />
                <VoterImage id={voter.id} src="/images/User_font_awesome.svg" fill={color} onClick={() => {
                    if (isMobile) return;
                    window.addEventListener("click", function blah() {
                        let voterImage = document.getElementById(`${voter.id}`)!.firstElementChild;
                        const resultsButton = document.getElementById("resultsButton");
                        const parent = event && (event.target as HTMLElement).parentElement
                        const child = event && (event.target as HTMLElement)
                        if (event && parent === voterImage) {
                            setRankedVote({
                                id: voter.id,
                                votes: voter.vote
                            })
                            console.log("Voter Clicked")
                        }

                        else if (event && event.target === resultsButton || event && child === resultsButton!.firstChild) {
                            console.log("resultsButton innerText", resultsButton!.innerText)
                            if (resultsButton!.innerText === "First Choice") {
                                window.removeEventListener("click", blah);
                                setRankedVote(null);
                            }
                            console.log("Button Clicked")
                        }

                        else {
                            console.log("Window Clicked")
                            window.removeEventListener("click", blah);
                            setRankedVote(null);
                        }


                    })


                }} />
            </VoterImageContainer>
        </>
    )

}

import styled, { css } from 'styled-components'
import SVG from 'react-inlinesvg'

export const MOBILEBREAKPOINT = 600

export const Main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
export const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    align-self: flex-start;
    width: 100%;
    
    @media(max-width: ${MOBILEBREAKPOINT}px){
      justify-content: center;
      margin-top: 25px;
    }
`
export const HeaderText = styled.div`
    margin-top: 25px;
    font-size: 1.6vw;
    font-weight: 900;
    letter-spacing: 5px;
    color: wheat;
    padding-top: 11px;
    padding-bottom: 14px;
    padding-left: 35px;
    padding-right: 35px;
    background-color: cornflowerblue;
    user-select: none;
    
    @media(max-width: ${MOBILEBREAKPOINT}px){
      font-size: 5vw;    
      text-align: center;
    }
`

export const ResultsButton = styled.div`
    cursor: pointer;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    display: flex;
    justify-content:center;
    align-self: center;
    margin-top: 3vh;
    margin-bottom: 28px;
    padding-left: 1.3vh;
    padding-right: 1.3vh;
    width: 4.7vw;
    box-shadow: 0px 1px 4px 0px;
    border: solid;
    border-color: cornflowerblue;
    border-width: 3px;
    border-radius: 12px;
    background-color: cornsilk;
    user-select: none;

    -webkit-touch-callout: none; 
    -webkit-user-select: none;     
    -khtml-user-select: none;
    -moz-user-select: none; 
    -ms-user-select: none; 
    
    @media(max-width: ${MOBILEBREAKPOINT}px){
      width: 21vw
    }
    
`
export const TextWrap = styled.div`
    text-align: center;
    font-size: .93vw;
    /* font-size: 1.3vh; */
    color: cornflowerblue;
    padding-top: .6vh;
    padding-bottom: .65vh;
    height: fit-content;
    
    @media(max-width: ${MOBILEBREAKPOINT}px){
      font-size: 4vw
    }
`

export const CandidateHeader = styled.div`
    align-self: flex-start;
    margin-top: 130px;
    font-weight: bold;
    font-size: 1.25vw;
    transform: rotate(-90deg);
    user-select: none;
`
export const CandidatesSvg = styled.svg`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 2vw;
    padding: 1px;
    font-size: 0;
    width: 7.5vw;
    height: 300px;
    /* height: 34.3vh; */
    user-select: none;
`

export const Tabs = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    align-self: flex-start;
    margin-top: -26px;
    margin-left: 17vw;
    width: 74%;
    
`
interface ITab {
    isSelected: boolean
}
export const Tab = styled.div<ITab>`
    display: flex;
    align-items: center;
    align-self: flex-start;
    align-content: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    background-color: white;
    border-radius: 8px 8px 0px 0px;
    border-width: 1px;
    border-style: dashed;
    border-top-color: black;
    border-right-color: black;
    border-left-color: black;
    border-bottom-color: ${props => props.isSelected ? "white" : "black"};
    width: 8%;
    height: 25px;
    user-select: none;
    cursor: pointer;
`
export const TabText = styled.div`
    font-size: 0.9vw;
`

export const BarChartContainerSvg = styled.svg`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border: 1px dashed black;
    margin-left: .5vw;
    width: 75vw;
    height: 300px;
    position: relative;
    font-family: 'Cutive Mono';
    
    @media(max-width: ${MOBILEBREAKPOINT}px){
      height: 25vh;
    }
 
`

export const MessageBanner = styled.div`
    align-self: center;
    margin-top: 2.5vh;
    text-align: center;
    font-size: 1.13vw;
    color: cornflowerblue;
    padding-top: 3.8vh;
    padding-bottom: 3.5vh;
    padding-left: 42px;
    padding-right: 42px;
    width: auto;
    height: fit-content;
    background-color: wheat;
    
    @media(max-width: ${MOBILEBREAKPOINT}px){
      font-size: 3.5vw;
    }
`

export const VotersHeader = styled.div`
    font-size: 1.25vw;
    font-weight: bold;
    text-align: center;
    margin-top: 25px;
    user-select: none;
    
    @media(max-width: ${MOBILEBREAKPOINT}px){
      font-size: 4vw;
      margin-top: 12px;
    }
    
`
export const VotersContainer = styled.div`
    margin-top: 3vh;
    justify-content: center;
    width: 77vw;
    display: flex;
    flex-wrap: wrap;
    
    @media(max-width: ${MOBILEBREAKPOINT}px){
      margin-top: 0;
    }
 
`
export const VoterImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 7vw;
    height: 7vh;
`
export const VoterImage = styled(SVG)`
    width: 7vw;
    height: 7vh;
    g {
        cursor: pointer;
    }
`
export const VoterModal = styled.div`
    display: flex;
    flex-direction: row;
    position: absolute;
    z-index: 1;
    /* margin-top: -117%; */
    margin-top: -152px;
    /* padding-right: .7vw; */
    width: auto;
    height: fit-content;
    background-color: white;
    border: 1px dashed black;
`
export const ModalHeader = styled.div`
    position: absolute;
    text-align: center;
    /* margin-top: .58vh; */
    margin-top: 6.5px;
    /* font-size: .9vw; */
    font-size: 17px;
    color: black;
    width: -webkit-fill-available;
    user-select: none;
`
export const NumberContainer = styled.div`
    white-space: pre;
    display: flex;
    flex-direction: column;
    width: auto;
    height: fit-content;
`
export const Numb = styled.div`
    text-align: center;
    /* margin-bottom: .45vh; */
    margin-bottom: 5.2px;
    padding: 0 10px 0 10px;
    /* font-size: 0.8vw; */
    font-size: 13.5px;
    color: black;
    user-select: none;
    :first-child {
    /* margin-top: 3.4vh; */
    margin-top: 32px;
    margin-bottom: 6.2px;
    }
    :last-child {
        margin-bottom: 0px;
    }
`
export const TextContainer = styled.div`
    white-space: pre;
    display: flex;
    flex-direction: column;
    padding-right: 14px;
    padding-bottom: 14px;
    width: auto;
    height: fit-content;
`
interface IColor {
    candidateColor: string
}
export const ModalText = styled.div<IColor>`
    text-align: center;
    /* margin-bottom: .45vh; */
    margin-bottom: 5px;
    padding: 0 20px 0 20px;
    /* font-size: 0.8vw; */
    font-size: 14px;
    color: black;
    background-color: ${props => props.candidateColor};
    user-select: none;
    :first-child {
    /* margin-top: 3.4vh; */
    margin-top: 31.5px;
    }
    :last-child {
        margin-bottom: 0px;
    }
`
export const PointerArrowDiv = styled.div`
    display: flex;
    position: absolute;
    z-index: 1;
    margin-left: 161px;
`

export const DisplayRankedVote = styled.div`
    display: flex;
    align-self: center;
    text-align: center;
    font-size: 1.13vw;
    color: black;
    padding-top: 3.8vh;
    width: auto;
    height: fit-content;
`
interface IBox {
    width?: string;
    height?: string;
    direction?: string;
    backgroundColor?: string;
    fontSize?: string;
    flexWrap?: string;
    marginBottom?: string;
    marginRight?: string;
    marginTop?: string;
    justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around"
}
export const Box = styled.div<IBox>`
   display: flex;
   flex-direction: ${props => props.direction || "row"};
   width: ${props => props.width || "auto"};
   height: ${props => props.height || "auto"};
   background-color: ${props => props.backgroundColor || "inherit"};
   font-size: ${props => props.fontSize || "inherit"};
   flex-wrap: ${props => props.flexWrap || "wrap"};
   margin-bottom: ${props => props.marginBottom || '0px'};
   margin-right: ${props => props.marginRight || '0px'};
   justify-content: ${props => props.justifyContent || 'flex-start'};
   margin-top:${props => props.marginTop || '0px'};
`

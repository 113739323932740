import React from 'react';
import NodeGroup from 'react-move/NodeGroup';
import { BarChartContainerSvg, MOBILEBREAKPOINT } from './styled'
import { HashMap } from 'react-move'
import { ICandidate, IVoter } from '../types'
import {getTotalCandidateVotes} from '../screens/Simulation.'

interface IBarChartProps {
    candidates: Array<ICandidate>
    voters: Array<IVoter>
}
export function SingleBarChart({ candidates, voters }: IBarChartProps) {
    return (
        <BarChartContainerSvg>
            <NodeGroup
                data={candidates}
                keyAccessor={keyAccessor}
                start={startTransition}
                enter={enterTransition}
                update={updateTransition}
                leave={leaveTransition}
            >
                {transitionNodes(voters)}
            </NodeGroup>
        </BarChartContainerSvg>
    )
}

function transitionNodes(voters: Array<IVoter>) {
    const isMobile = window.innerWidth < MOBILEBREAKPOINT
    return (nodes: Array<any>): JSX.Element => {
        const elements = nodes.map(({ key, data, state }) => {
            const { voteCount, color } = data as ICandidate
            const { width, y } = state as { width: number; y: number; }
            const totalCandidateVotes = getTotalCandidateVotes(data)
            const dx = totalCandidateVotes > 9 ? "-25" : "-15"
            return <g key={key} width="75vw" transform={`translate(0, ${y})`}>
                <rect width={`${(width / voters.length) * 100}%`} height="24px" fill={color} />
                <text
                    x={`${((width / voters.length) * 100)}%`}
                    y={12}
                    dx = {dx}
                    alignmentBaseline="central"
                    fontSize={isMobile ? "16px" : ".8vw"}
                >
                    {totalCandidateVotes.toFixed(0)}
                </text>
            </g>
        })
        return <>{elements}</>
    }
}

function keyAccessor(data: ICandidate, index: number) {
    return data.name
}

function startTransition(data: ICandidate, index: number): HashMap {
    return {
        width: 0,
        y: getBarOffset(index)
    }
}

function enterTransition(data: ICandidate, index: number): HashMap | Array<HashMap> {
    return [{
        width: [getTotalCandidateVotes(data)],
        timing: { duration: 500 },
        y: [getBarOffset(index)]
    }]
}

function updateTransition(data: ICandidate, index: number): HashMap | Array<HashMap> {
    return [{
        width: [getTotalCandidateVotes(data)],
        y: [getBarOffset(index)]
    }]
}

function leaveTransition(data: ICandidate, index: number): HashMap | Array<HashMap> {
    return [{
        opacity: [0]
    }
    ]
}

function getBarOffset(index: number): number {
    const isMobile = window.innerWidth < MOBILEBREAKPOINT
    if (isMobile) {
        return index * (Math.round(window.innerHeight / 19))
    }
    return (index + .2) * 63
}

import {useState, useEffect} from 'react'

export function useElementSize(id : string) {
    const isClient = typeof window === 'object'
    const element = document.getElementById(id)

    function getSize() {
        return {
            width: isClient ? element?.clientWidth : undefined,
            height: isClient ? element?.clientHeight : undefined
        }
    }

    const [windowSize, setWindowSize] = useState(getSize)

    useEffect(() => {
        if (!isClient) {
            return
        }

        function handleResize() {
            console.log('Window Resized' )
            setWindowSize(getSize())
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, []) // Empty array ensures that effect is only run on mount and unmount

    return windowSize
}
import React, { useState, useEffect } from 'react'
import NodeGroup from 'react-move/NodeGroup'
import {BarChartContainerSvg,  MOBILEBREAKPOINT} from './styled'
import {HashMap} from 'react-move'
import {ICandidate, IVoter, IRound} from '../types'
import {getTotalCandidateVotes} from '../screens/Simulation.'
import { Bar, IBarCandidate } from './Bar'

export interface IBarChartProps {
    candidates: Array<ICandidate>
    voters: Array<IVoter>
    round: IRound
}

export function MultiBarChart({candidates, voters, round}: IBarChartProps) {
    const [isFirstTransition, setFirstTransition] = useState<boolean>(true)
    useEffect(()=> {
        setFirstTransition(false)
    }, [])
    return (
        <BarChartContainerSvg>
            <NodeGroup
                data={candidates}
                keyAccessor={keyAccessor}
                start={startTransition}
                enter={enterTransition}
                update={updateTransition}
                leave={leaveTransition}
            >
                {transitionNodes(voters, round, isFirstTransition)}
            </NodeGroup>
        </BarChartContainerSvg>
    )
}
type BarKey = string
type TopCandidate = string
type BarStates = Map<BarKey, BarState>
type BarState = Map<TopCandidate, number>
type CandidateVotes = Map<BarKey, number>
type BarCandidates = Map<BarKey, Array<IBarCandidate>>
console.log('Initializing BarChart State')
let barStates : BarStates = new Map()
let oldCandidateVotes : CandidateVotes = new Map()
let barCandidates :BarCandidates = new Map()
function transitionNodes(
    voters: Array<IVoter>,
    round: IRound,
    isFirstTransition: boolean){
    if(round === 1 || isFirstTransition){
        barStates = new Map()
        oldCandidateVotes = new Map()
        barCandidates = new Map()
    }
    return (nodes: Array<any>): JSX.Element => {
        const bars = nodes.map(({key, data, state}) => {
            const {voteCount, name} = data as ICandidate
            const {y, opacity} = state as { y: number, opacity: number}
            const vote = voters[0].vote
            const totalCandidateVotes = getTotalCandidateVotes(data)

            if(totalCandidateVotes === oldCandidateVotes.get(key)){
                // console.log('Same Candidate Votes', totalCandidateVotes)
                return(
                    <g key={key} transform={`translate(0, ${y})`}>
                        <Bar barCandidates = {barCandidates.get(key)!} voters = {voters} totalCandidateVotes = {totalCandidateVotes}/>
                    </g>
                )
            }
            console.group('Candidate Update', key)
            oldCandidateVotes.set(key, totalCandidateVotes)
            if(!barStates.has(key)){
                barStates.set(key, new Map())
            }
            const barState = barStates.get(key) as BarState
            let lastWidth = 0;
            let timeOffset = 0;
            voteCount.sort((a, b) => a.rank - b.rank)
            barCandidates.set(key, vote.map((_, index) => {
                if(!(voteCount.length > index)){
                   return {width: 0, voterTopChoice: `${index}${name}`, x: lastWidth} as IBarCandidate
                }
                const topCandidate = voteCount[index]
                const {count, color, voterTopChoice} = topCandidate
                console.log('BarState', key,  barState)
                console.log(`Barstate has ${voterTopChoice}`, barState.has(voterTopChoice))
                const rectWidth = (count / totalCandidateVotes * totalCandidateVotes) / voters.length * 100
      
                if(barState.has(voterTopChoice)){
                    const oldWidth = barState!.get(voterTopChoice)
                    console.log('Old Width', oldWidth)
                    console.log('New Width',rectWidth)
                    const isUpdate = Math.ceil(rectWidth) !== Math.ceil(oldWidth || 0)
                    timeOffset = !isUpdate ? timeOffset + 1 : timeOffset
                }
                barState.set(voterTopChoice, rectWidth)
                const barCandidate : IBarCandidate = {width: rectWidth, color, voterTopChoice, x: lastWidth}
                lastWidth = rectWidth + lastWidth
                return barCandidate
            }))
            const totalCountWidth =  totalCandidateVotes / voters.length * 100
            if(isFirstTransition && round !== 1){
                timeOffset = -1
            }
            if(totalCandidateVotes > 0){
                barCandidates.get(key)!.push({voterTopChoice: "totalVotes",  width:totalCountWidth, x: 0, voteCount: voteCount.length})
                barCandidates.get(key)!.forEach((barCandidate)=> {
                    barCandidate.timeOffset = timeOffset
                })
            }
            console.log('Timeoffset:', timeOffset)
            console.groupEnd()
            return <g key={key} opacity = {opacity} transform={`translate(0, ${y})`}>
                <Bar barCandidates = {barCandidates.get(key)!} voters = {voters} totalCandidateVotes = {totalCandidateVotes}/>
            </g>
        })
        return <>{bars}</>
    }
}

function keyAccessor(data: ICandidate, index: number) {
    return data.name
}

function startTransition(data: ICandidate, index: number): HashMap {
    return {
        y: getBarOffset(index)
    }
}

function enterTransition(data: ICandidate, index: number): HashMap | Array<HashMap> {
    return [{
        timing: { duration: 500 },
        y: [getBarOffset(index)]
    }]
}

function updateTransition(data: ICandidate, index: number): HashMap | Array<HashMap> {
    return [{
        timing: {duration: 500},
        y: [getBarOffset(index)]
    }]
}

function leaveTransition(data: ICandidate, index: number): HashMap | Array<HashMap> {
    return [{
        opacity: [0],
        timing: {duration: 500}
    }
    ]
}

function getBarOffset(index: number): number {
    const isMobile = window.innerWidth < MOBILEBREAKPOINT
    if (isMobile) {
        return index * (Math.round(window.innerHeight / 19))
    }
    return (index + .2) * 63
}
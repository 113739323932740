import React from 'react'
import {ICandidate} from '../types'
import {Box} from './styled'
import cloneDeep from 'lodash.clonedeep'


interface ICandidatesLegend {
    candidates: Array<ICandidate>
}

export function CandidatesLegend({candidates}: ICandidatesLegend) {
    const sortedCandidates = cloneDeep(candidates)
    sortedCandidates.sort((a, b) =>
        a.name.localeCompare(b.name)
    )
    return (
        <Box width="75vw" direction="column" marginTop="10px">
            <Box justifyContent="center" marginBottom="10px">Candidates Legend</Box>
            <Box direction="column">
                {sortedCandidates.map((candidate) =>
                    <Box marginBottom="10px">
                        <Box
                            backgroundColor={candidate.color}
                            width='15px'
                            height='15px'
                            marginRight="10px"
                        />
                        <Box marginRight="10px" fontSize="4vw">{candidate.name}</Box>
                    </Box>
                )}
            </Box>
        </Box>
    )
}